import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { css } from '@emotion/core'
import { Container } from '@material-ui/core'

const BlogHeader = ({ postData }) => {
  const data = useStaticQuery(graphql`
  query{
    file(relativePath: { eq: "images/standardHeader.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920){
          ...GatsbyImageSharpFluid
        }
      }
    }
  }`
  );

  return (
    <BackgroundImage
      fluid={postData.featured_media.localFile.childImageSharp.fluid ? postData.featured_media.localFile.childImageSharp.fluid : data.file.childImageSharp.fluid}
      css={theme => css`
      height: 250px;
      display: flex;
      align-items: flex-end;
      color: ${theme.colors.white};
      margin-bottom: 25px;
    `
      }
    >
      <Container>
        <div
          css={theme => css`
              color: white;
              padding: 16px 12px;
              background-color: ${theme.colors.white};
              margin: 0 auto;
              color: ${theme.colors.black};
              border-top-left-radius: 10px;
              border-top-right-radius: 10px;
              position: relative;
              text-align: center;
              bottom: -10px;
              ${theme.breakpoints.md('max-width: 100%; text-align: center;')}
            `}
        >
          <h1
            css={theme => css`
              font-size: 40px;
              margin: 6px 0;
              &::after{
                content: '';
                width: 300px;
                height: 2px;
                background-color: ${theme.colors.green};
                display: block;
                margin: 8px auto;
              }
              ${theme.breakpoints.md('font-size: 30px')}
              ${theme.breakpoints.sm('font-size: 25px')}
            `}
            dangerouslySetInnerHTML={{ __html: postData.title }}
          />
          <p
            css={css`
                margin: 0;
              `}
          >By {postData.author.name}</p>
          <div
            css={theme => css`
              margin-top: 5px;
              a {
                color: ${theme.colors.dgrey};
                display: inline-block;
              }
              a span {
                margin: 0 3px;
              }
            `}
          >
            {postData.categories.map((category, index) => 
              <Link to={`/blog/${category.slug}`}>
                {category.name}
                <span>{index + 1 === postData.categories.length ? '' : `/`}</span>
              </Link>
            )}
          </div>
        </div>
      </Container>
    </BackgroundImage>)
}

export default BlogHeader;