import React, { useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import { css } from "@emotion/core"
import BlogHeader from "../components/Blog/blogHeader"
import { Grid } from "@material-ui/core"
import BlogSidebar from "../components/Blog/SideBar"
import Breadcrumbs from "../components/MainComponents/Breadcrumbs"

export default function BlogPost({ data, location }) {
  useEffect(() => {
    ;[].forEach.call(
      document.querySelectorAll(".blog-content-container .adsbygoogle"),
      function (index) {
        console.log({ index })
        if (index.classList.contains("adsbygoogle-noablate")) return null
        if (window.adsbygoogle) return window.adsbygoogle.push({})
        ;(window.adsbygoogle = window.adsbygoogle || []).push({})
      }
    )
  }, [])
  const post = data.allWordpressPost.edges[0].node
  return (
    <Layout PageHeader={() => <BlogHeader postData={post} />}>
      <SEO
        title={post.title}
        description={post.excerpt.replace(/<[^>]*>/, "")}
        image={post.featured_media.localFile.url}
        url={location.pathname}
        meta={[
          {
            property: `og:type`,
            content: `article`,
          },
        ]}
      />
      <Breadcrumbs url={location.pathname} />
      <div
        css={css`
          .MuiGrid-root.MuiGrid-item.MuiGrid-grid-sm-8 {
            width: 100%;
          }
        `}
      >
        <Grid container spacing={3}>
          <Grid className={"blog-content-container"} item sm={8}>
            <div
              css={theme => css`
                word-break: break-word;
                img {
                  width: 100%;
                  height: auto;
                }
                figure {
                  margin: 0;
                  max-width: 100%;
                }
                ${theme.blogPosts}
                .adsbygoogle {
                  width: 100% !important;
                }
              `}
              dangerouslySetInnerHTML={{ __html: post.content }}
            />
            <div
              css={css`
                .adsbygoogle {
                  display: block;
                  text-align: center;
                  margin-top: 10px;
                  width: 100% !important;
                }
              `}
            >
              <ins
                class="adsbygoogle"
                style={{ display: "block", textAlign: "center" }}
                data-ad-layout="in-article"
                data-ad-format="fluid"
                data-ad-client="ca-pub-7046612602607474"
                data-ad-slot="6033480175"
              ></ins>
            </div>
          </Grid>
          <Grid
            item
            sm={4}
            css={css`
              width: 100%;
            `}
          >
            <BlogSidebar showAd={false} />
          </Grid>
        </Grid>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query($slug: String!) {
    allWordpressPost(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          title
          content
          excerpt
          author {
            name
          }
          fimg_url {
            source_url
          }
          categories {
            name
            slug
          }
          featured_media {
            localFile {
              url
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
