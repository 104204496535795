import React, { Fragment, useEffect } from "react"
import { css } from "@emotion/core"

const BlogSidebar = ({ showAd = true }) => {
  useEffect(() => {
    if (showAd) {
      ;(window.adsbygoogle = window.adsbygoogle || []).push({})
    }
  }, [])
  return (
    <Fragment>
      <div
        css={theme => css`
          margin-top: 16px;
          ${theme.boxShadow.lgrey};
        `}
      >
        <div
          css={theme => css`
            background-color: #ebebeb;
            min-height: 200px;
            max-height: 100%;
            height: 100%;
            width: 100%;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 15px 0;
          `}
        >
          <div
            css={css`
              padding: 0 20px;
            `}
          >
            <h4
              css={theme => css`
                font-size: 22px;
                font-weight: 500;
                margin: 0 0 12px;
                ${theme.line()}
              `}
            >
              Green Finance Guide
            </h4>
            <p
              css={css`
                margin-bottom: 0px;
              `}
            >
              Green Finance Guide is a source of green financial, renewable
              energy and climate change news and insight
            </p>
          </div>
        </div>
        <div
          css={theme => css`
            background-color: ${theme.colors.green};
            min-height: 50px;
            max-height: 100%;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
          `}
        >
          <a
            href="https://twitter.com/GreenFinGuide"
            css={theme => css`
              color: ${theme.colors.white};
              text-decoration: none;
              font-weight: 600;
              padding: 0 15px;
              text-align: center;
              width: 100%;
            `}
          >
            To stay up to date follow us on Twitter
          </a>
        </div>
      </div>
      <div
        css={css`
          width: 100%;
          .sidebar_ad {
            display: block;
            margin-top: 10px;
            width: 100% !important;
          }
        `}
      >
        {showAd && (
          <ins
            class="adsbygoogle sidebar_ad"
            data-ad-client="ca-pub-7046612602607474"
            data-ad-slot="5020764951"
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
        )}
      </div>
    </Fragment>
  )
}

export default BlogSidebar
